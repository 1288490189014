import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import Programacao from '../components/DownloadMaterial'
import Footer from '../components/Footer'
import { globalStyles } from '../components/layout.styles'

import {
  inspired,
  container,
  title,
  titleSection,
  videoPlay
} from '../components/styles/AoVivo.styles'

const SalaEnem = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Aulão Brasil - Os melhores professores, das melhores escolas do Brasil." />
    <NavBar location={props.location} />
    <div css={inspired} >
      <div css={container} style={{ paddingBottom: 80 }}>
        <div css={title} style={{ fontSize: 24, color: '#fff', lineHeight: '32px' }}>Aulão Sábado 8 de agosto sala ENEM<br /> Professor Wendel Santos</div>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <div css={videoPlay}>
            <div><iframe title='Sala IME/ITA' src="https://player.vimeo.com/video/447940665" width='800' height='500' frameborder="0" allow="autoplay; fullscreen" allowfullscreen /></div>
          </div>
        </div>
      </div>
    </div>
    <div style={{ background: 'rgb(104, 62, 162)' }}>
      <div css={titleSection}>
        <span>Material</span><br />
        Aulões anteriores
      </div>
    </div>
    <Programacao />
    <div style={{ marginTop: 80 }} />
    <Footer />
  </Fragment>
)

export default SalaEnem
